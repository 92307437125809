import { CircularProgress, Menu, MenuItem, makeStyles } from "@material-ui/core"
import { MoreVertOutlined } from "@material-ui/icons"
import React, { useEffect, useRef, useState } from "react"
import { useProduct } from "../../ContextAPI/productContext"
import { useSettings } from "../../ContextAPI/settingsContext"
import ativarDesativarRequest from "../../Services/api/ativardesativarproduto"
import produtosRequest from "../../Services/api/produtos"
import CustomAlert from "../Alerts/CustomAlert"
import BannerSejaProListProduct from "../BannerSejaProListProduct"
import ProductCard from "../ProdutoCard"

const useStyles = makeStyles({
  Loading: {
    display: "flex",
    height: "100%",
    minHeight: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  MoreBtn: {
    backgroundColor: "transparent",
    border: "none",
    color: " #EA3458",
    display: "flex",
    textAlign: "center",
    margin: "10px auto",
    fontSize: "1.2rem",
    padding: "5px 10px",
    borderRadius: "5px"
  }
})

function InfiniteScroll({ handleEdit, handleDelete }) {
  const classes = useStyles()
  const { query, setAllProductList } = useProduct()
  const [productListState, setProductListState] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const [hasMore, setHasMore] = useState(true)
  const { lojinhaInfo } = useSettings()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [loading, setLoading] = React.useState(false)
  const [alertType, setAlertType] = React.useState("")
  const [showModalAlert, setShowModalAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [productId, setProductId] = React.useState(null)
  const [showProduct, setShowProduct] = React.useState(null)
  const [deleteProduct, setDeleteProduct] = useState(false)
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2
  const [successAlert, setSuccessAlert] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false);

  const onDelete = product => {
    setSelectedProduct(product)
    handleClick()
  }

  const loadNextPage = async () => {
    if (loading) return;

    setLoading(true);

    try {
      const { data } = await produtosRequest.getAll(page, query);
      const newProductList = data.data.data;

      if (newProductList.length > 0) {
        setProductListState(prevProductList => [...prevProductList, ...newProductList]);
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const applyFilter = async () => {
    try {
      setPage(1);
      setHasMore(true);
      setIsLoading(true);
      const { data } = await produtosRequest.getAll(1, query);
      const newProductList = data.data.data;

      if (newProductList.length > 0) {
        setProductListState(newProductList);
        setPage(2);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (!isLoading) {
        setShowModalAlert(false);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    applyFilter();
  }, [query]);


  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore) {
        loadNextPage();
      }
    }
  };

  const handleClick = (event, productId, showProduct) => {
    getProduct(productId)
    setAnchorEl(event.currentTarget)
    setProductId(productId)
    setShowProduct(showProduct)
  }

  const getProduct = productId => {
    setSelectedProduct(productId)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteMenuItem = () => {
    setDeleteProduct(true)
    handleDelete(selectedProduct)
    handleCloseMenu()
  }

  const handleToggleActivation = async () => {
    try {
      setIsUpdating(true);
      setIsLoading(true);

      const { data } = await ativarDesativarRequest.put(productId, {
        active: showProduct === 1 ? 0 : 1
      });

      if (data.error === false) {
        setSuccessAlert(true);
        setAlertType("success")
        setAlertMessage(
          showProduct === 1
            ? "Produto desativado com sucesso!"
            : "Produto ativado com sucesso!"
        )
        setShowModalAlert(true)
        setProductListState(prevProductList => {
          return prevProductList.map(product => {
            if (product.id === productId) {
              return {
                ...product,
                show_product: showProduct === 1 ? 0 : 1
              };
            }
            return product;
          });
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
      setIsLoading(false);
    }
  };


  return (
    <>
      {isLoading ? (
        <div className={classes.Loading} style={{ height: "600px" }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="infinite-scroll" onScroll={onScroll} ref={listInnerRef}>
          {productListState.map((product, index) => {
            if (index === 0) {
              return (
                <div key={product.id}>
                  <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                    <ProductCard
                      handleDelete={onDelete}
                      handleEdit={handleEdit}
                      product={product}
                      isActive={product.show_product === 1}
                      className={product.isActive ? "" : "disabled"}
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button"
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleToggleActivation();
                          handleCloseMenu();
                        }}
                      >
                        Ativar/Desativar
                      </MenuItem>
                      <MenuItem onClick={handleDeleteMenuItem}>Deletar</MenuItem>
                    </Menu>
                    <MoreVertOutlined
                      style={{
                        fontSize: "2.3em",
                        color: "#F21C62",
                        cursor: "pointer"
                      }}
                      onClick={event =>
                        handleClick(event, product.id, product.show_product)
                      }
                    />
                  </div>
                  {!hasPro && (
                    <BannerSejaProListProduct bannerId="banner-list-product" />
                  )}
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>
              );
            }
            return (
              <div >
                <div key={product.id} style={{ display: "flex", alignItems: "center" }}>
                  <ProductCard
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    product={product}
                    isActive={product.show_product === 1}
                    className={product.isActive ? "" : "disabled"}
                  />
                  <MoreVertOutlined
                    style={{
                      fontSize: "2.3em",
                      color: "#F21C62",
                      cursor: "pointer"
                    }}
                    onClick={event =>
                      handleClick(event, product.id, product.show_product)
                    }
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleToggleActivation();
                        handleCloseMenu();
                      }}
                    >
                      Ativar/Desativar
                    </MenuItem>
                    <MenuItem onClick={handleDeleteMenuItem}>Deletar</MenuItem>
                  </Menu>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>
            );
          })}
          <div>
            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showModalAlert}
              close={() => setShowModalAlert(true)}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default InfiniteScroll
