import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => {
  return {
    banner: {
      display: "flex",
      justifyContent: "space-between",
      height: "80px",
      width: "100%",
      background: "#051626",
      padding: "0 15px",
      boxSizing: "border-box"
    },

    bannerLarge: {
      display: "flex",
      justifyContent: "space-between",
      height: "10%",
      width: "100%",
      background: "#051626",
      padding: "0 15px",
      boxSizing: "border-box"
    },

    bannerLeftSide: {
      display: "flex"
    },

    imgBanner: {
      height: "80px",
      width: "80px",
      padding: "10px 10px"
    },

    titleBanner: {
      fontSize: "2vw",
      margin: "5px 0px",
      color: "#fff",
      display: "flex",
      alignItems: "center"
    },

    closeBtn: {
      height: "40px",
      width: "40px",
      color: "#fff",
      margin: "0px"
    }
  }
})
