import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import api from "../Services/api"
import lojinhaRequest from "../Services/api/lojinha"
import lojinhaConfigsRequest from "../Services/api/lojinhaConfigs"

export const SettingsContext = createContext({})

export function useSettings() {
  const context = useContext(SettingsContext)
  if (!context) {
    throw new Error("useSettings must be used inside SettingsProvider")
  }

  return context
}

export function SettingsProvider(props) {
  const { children } = props
  const [lojinhaConfigs, setLojinhaConfigs] = useState({})
  const [lojinhaInfo, setLojinhaInfo] = useState({})

  const [primaryColorsPallete] = useState([
    "#1ABC9C",
    "#2ECC71",
    "#3498DB",
    "#9B59B6",
    "#FD79A8",
    "#EA3458",
    "#F1C40F",
    "#E67E22",
    "#E74C3C",
    "#3D3D3D"
  ])

  const [secondaryColorsPallete] = useState([
    "#55EFC4",
    "#3AED86",
    "#74B9FF",
    "#A29BFE",
    "#FFCCCC",
    "#EA3458",
    "#FFFA65",
    "#FFAF40",
    "#FF4D4D",
    "#3D3D3D"
  ])

  const [primaryColor, setPrimaryColor] = useState("#1ABC9C")
  const [secondaryColor, setSecondaryColor] = useState("#55EFC4")
  const [progressCount, setProgressCount] = useState(0)
  const [profileProgress, setProfileProgress] = useState({
    logo: false,
    info: false,
    payment: false,
    shipping: false,
    product: false
  })

  useEffect(() => {
    getLojinhaInfo()
    getLojinhaConfig()
    getShipping()
    getPayment()
    getHasProduct()
  }, [])

  /* loja info */
  const getLojinhaInfo = async () => {
    const infoRes = await lojinhaRequest.show()
    if (infoRes.data.error === false) {
      const { sm_url, whatsapp, description } = infoRes.data.data
      if (sm_url !== null) profileProgress.logo = true
      if (whatsapp !== null) profileProgress.info = true
      handleUpdateProgress({ ...profileProgress })
      setLojinhaInfo(infoRes.data.data)
    }
  }
  /* loja config */
  const getLojinhaConfig = async () => {
    const configRes = await lojinhaConfigsRequest.getByLojinhaId()
    if (configRes && configRes.data.data[0]) {
      setLojinhaConfigs(configRes.data.data[0])
      localStorage.setItem("config_id", configRes.data.data[0].id)
      if (
        configRes.data.data[0].primary_color &&
        configRes.data.data[0].secondary_color
      ) {
        handlePrimaryColor(configRes.data.data[0].primary_color)
        handleSecondaryColor(configRes.data.data[0].secondary_color)
      }
    }
  }
  /* shipping info */
  const getShipping = async () => {
    const shopId = localStorage.getItem("id")
    if (shopId !== null) {
      const { data } = await api.get(`/lojinha/shipping/list/${shopId}`)
      if (data.data) {
        if (
          data.data[0] &&
          data.data[0].shipping_data.shipping_name === "A Combinar" &&
          data.data[0].shipping_data.shipping_active === 1 &&
          data.data[0].shipping_data.average_time === "1" &&
          data.data[0].shipping_data.average_time_type === "days" &&
          data.data[0].shipping_type === "custom_shipping"
        ) {
          profileProgress.shipping = false
        } else {
          profileProgress.shipping = true
        }
        handleUpdateProgress({ ...profileProgress })
      }
    }
  }
  /* active checkout */
  const activeCheckout = async () => {
    const shopId = localStorage.getItem("id")
    if (shopId !== null) {
      const { data } = await api.get(`lojinha/payment/get/${shopId}`)
      if (data.data) {
        localStorage.setItem("active_checkout", data.data?.payment_active)
        localStorage.setItem("payment_id", data.data?.id)
      }
    }
  }
  /* payment info */
  const getPayment = async () => {
    const shopId = localStorage.getItem("id")
    if (shopId !== null) {
      const { data } = await api.get(`/lojinha/payment/get/${shopId}`)

      if (data.data) {
        if (
          data.data &&
          data.data.payment_active === "manual" &&
          data.data.payment_data.manual.payment_type_list[0].active === 0 &&
          data.data.payment_data.manual.payment_type_list[1].active === 0 &&
          data.data.payment_data.manual.payment_type_list[2].active === 0 &&
          data.data.payment_data.manual.payment_type_list[3].active === 0 &&
          data.data.payment_data.manual.payment_type_list[4].active === 0 &&
          data.data.payment_data.manual.payment_type_list[5].active === 0 &&
          data.data.payment_data.manual.payment_type_list[6].active === 0 &&
          data.data.payment_data.manual.payment_type_list[7].active === 0 &&
          data.data.payment_data.manual.payment_type_list[8].active === 1
        ) {
          profileProgress.payment = false
        } else {
          profileProgress.payment = true
        }
        handleUpdateProgress({ ...profileProgress })
      }
    }
  }
  /* get has product */
  const getHasProduct = async () => {
    const shopId = localStorage.getItem("id")
    const { data } = await api.post(`/product/list`, {
      lojinha_id: shopId,
      per_page: 1
    })

    if (data.data && data.data.data.length) {
      profileProgress.product = true
      handleUpdateProgress({ ...profileProgress })
    }
  }

  useEffect(() => {
    activeCheckout()
    getLojinhaConfig()
  }, [])

  useEffect(() => {
    let count = 0
    if (profileProgress.info) count++
    if (profileProgress.logo) count++
    if (profileProgress.payment) count++
    if (profileProgress.product) count++
    if (profileProgress.shipping) count++
    handleUpdateProgressCount(count)
  }, [profileProgress])

  const handlePrimaryColor = color => {
    setPrimaryColor(color)
  }

  const handleSecondaryColor = color => {
    setSecondaryColor(color)
  }

  const handleUpdateProgress = data => {
    setProfileProgress(data)
  }
  const handleUpdateProgressCount = value => {
    setProgressCount(value)
  }
  const updateLojinha = async data => {
    const resLojinha = await lojinhaRequest.put({ ...data })
    if (resLojinha.data.error === false) return true
    return false
  }

  const updateLojinhaConfigs = async data => {
    const resLojinhaConfigs = await lojinhaConfigsRequest.put(
      localStorage.getItem("id"),
      {
        ...data
      }
    )
    if (resLojinhaConfigs.data.error === false) return true
    return false
  }

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        primaryColorsPallete,
        secondaryColorsPallete,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        handlePrimaryColor,
        handleSecondaryColor,
        updateLojinha,
        updateLojinhaConfigs,
        lojinhaConfigs,
        lojinhaInfo,
        setLojinhaInfo,
        profileProgress,
        setProfileProgress,
        handleUpdateProgress,
        progressCount,
        setProgressCount,
        getLojinhaInfo,
        getLojinhaConfig,
        getShipping,
        getPayment,
        getHasProduct
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
