import React, { useState } from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import { globalStyles } from "../../Componentes/CssGlobal"
import PlanosAlertaModal from "../Planos/Alertas/AlertaModal"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import { useSettings } from "../../ContextAPI/settingsContext"

export default function MenuComplements() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function handleCloseModal() {
    setOpenModal(false)
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Complementos" />
          </div>
          <div className="infinite-scroll">
            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/atendimento")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Atendimento</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Cadastre um email para receber um formulário com dúvidas de seus
                  clientes.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/trocas-devolucoes")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Trocas e devoluções</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Descreva as políticas de trocas e devoluções da sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/politicas-de-privacidade")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>
                Políticas de privacidade
              </div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Descreva as políticas de privacidade da sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/duvidas-frequentes")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Dúvidas frequentes</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina as principais dúvidas dos seus clientes, para que novos
                  clientes possam vê-las.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/formas-de-pagamento")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Formas de pagamento</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Descreva as formas de pagamento da sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/formas-de-entrega")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Formas de entrega</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Descreva as formas de entrega da sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            {hasPro ? "" : <BannerSejaPro bannerId="banner-personalizar-loja" />}
          </div>
        </div>
      </div>

      {openModal ? (
        <PlanosAlertaModal
          isOpen={openModal}
          handleClose={handleCloseModal}
          bannerId="modal-oops-personalizar-loja"
        />
      ) : (
        ""
      )}
    </>
  )
}
