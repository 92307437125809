import { CircularProgress, LinearProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import { BsCreditCard2Back } from "react-icons/bs"
import { FaLock } from "react-icons/fa"
import { SiPix } from "react-icons/si"
import { useHistory } from "react-router-dom"
import Topo from "../../../Componentes/TopCategorias"
import api from "../../../Services/api"
import CreditCard from "./components/CreditCard"
import Pix from "./components/Pix"
import { useStyles } from "./style"

export default function NewCheckoutPayment() {
  const [enableCreditCard, setEnableCreditCard] = useState(true)
  const [enablePix, setEnablePix] = useState(false)
  const classes = useStyles({ enable: enableCreditCard })

  const initialTimeCounter = 8 * 60
  const [timeLeftCounter, setTimeLeftCounter] = useState(initialTimeCounter)
  const [minutesCounter, setMinutesCounter] = useState(0)
  const [secondsCounter, setSecondsCounter] = useState(0)

  const [priceWithCoupon, setPriceWithCoupon] = useState(0)

  const [loadPage, setLoadPage] = useState(true)
  const history = useHistory()

  useEffect(async () => {
    const couponActive = sessionStorage.getItem("cupom_checkout")
    if (couponActive) {
      try {
        const res = await api.get(`/cupom/validate/${couponActive}`)
        setPriceWithCoupon(res.data.data.new_value)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadPage(false)
      }
    }
    setLoadPage(false)
  }, [])

  function changeEnableCreditCard() {
    setEnableCreditCard(true)
    setEnablePix(false)
  }

  function changeEnablePix() {
    setEnableCreditCard(false)
    setEnablePix(true)
  }

  useEffect(() => {
    if (timeLeftCounter >= 0) {
      const interval = setInterval(() => {
        setTimeLeftCounter(state => state - 1)
        setMinutesCounter(Math.floor(timeLeftCounter / 60))
        setSecondsCounter(timeLeftCounter % 60)
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
    history.push("/planos/v2")
  }, [timeLeftCounter])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: "#f9f9f9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "80vh"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <div className="page-container">
          <div className="page-align" style={{ justifyContent: "flex-start" }}>
            <div className="infinite-scroll">
              <div>
                <Topo name="Checkout" rota="/planos/v2" />
              </div>
              <div className={classes.counter}>
                <p>O tempo para você pagar acaba em:</p>
                <span>
                  {minutesCounter}:
                  {secondsCounter < 10 ? `0${secondsCounter}` : secondsCounter}
                </span>
                <LinearProgress
                  className={classes.progressBar}
                  variant="determinate"
                  value={(timeLeftCounter / initialTimeCounter) * 100}
                />
              </div>

              <div className={classes.wrapper}>
                <div className={classes.buttonWrapper}>
                  <button
                    className={classes.buttonCreditCard}
                    type="button"
                    onClick={() => changeEnableCreditCard()}
                  >
                    <BsCreditCard2Back
                      fontSize="24px"
                      color={enableCreditCard ? "#0D273D" : "#CCCCCC"}
                    />
                    <span>cartão de crédito</span>
                  </button>

                  <button
                    className={classes.buttonPix}
                    type="button"
                    onClick={() => changeEnablePix()}
                  >
                    <SiPix
                      fontSize="24px"
                      color={enableCreditCard ? "#CCCCCC" : "#0D273D"}
                    />
                    <span>pix</span>
                  </button>
                </div>

                <div className={classes.safeEnviroment}>
                  <FaLock color="#63BD38" />
                  <span>ambiente seguro</span>
                </div>
              </div>

              <div className={classes.componentContainer}>
                {enableCreditCard ? (
                  <CreditCard priceWithCoupon={priceWithCoupon} />
                ) : (
                  <Pix />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
