import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from "@material-ui/icons/Description";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Topo from "../../Componentes/TopCategorias";
import storiesRequest from "../../Services/api/stories";
import LabelStories from "../Stories/Components/LabelStories";
import { useStyles } from "./style";
export default function StoryCategories() {
  const classes = useStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([{}])
  const getData = async () => {
    try {
      setIsLoading(true)
      const { data } = await storiesRequest.getCategoryList()
      setCategoryList(data.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSelectCategory = index => {
    const currentCategory = categoryList[index]
    const currentCategoryId = currentCategory.category_id
    history.push({ pathname: "/produtos-stories", state: { currentCategoryId } })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="page-container">
          <div className="page-align infinite-scroll">
            <section className={classes.Section}>
              <header>
                <Topo name="Categorias" />
              </header>
              <main className="">
                <div className={classes.StoryContainer}>
                  <p>Selecione a categoria do seu story:</p>
                  {categoryList.length == 0 ?
                    <div className={classes.EmptyCategories}>
                      <p>Não há categorias ainda, comece cadastrando <a onClick={() => history.push("/categorias")}>aqui!</a></p>
                      <DescriptionIcon />
                    </div>
                    : categoryList.map((item, index) => {
                      return (
                        <LabelStories
                          key={index}
                          categoryName={item.category_name}
                          index={index}
                          onSelectCategory={onSelectCategory}
                        />
                      )
                    })}
                </div>
              </main>
            </section>
          </div>
        </div>
      )}
    </>
  )
}
