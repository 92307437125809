import { Button, CircularProgress } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomAlert from "../../Componentes/Alerts/CustomAlert";
import Topo from "../../Componentes/TopCategorias";
import { useProduct } from "../../ContextAPI/productContext";
import storiesRequest from "../../Services/api/stories";
import ProductsList from "./Components/ProductsList";

export default function StoryProducts(props) {
  const errorStyle = {
    color: "red",
    fontSize: "0.9rem"
  }
  const { currentCategoryId } = props.location.state
  const { currentStoryId } = props.location.state
  const history = useHistory()
  const { storiesProductsFiltredByCategory, setStoriesProductsFiltredByCategory } =
    useProduct()
  const [options, setOptions] = useState([])
  const [redirect, setRedirect] = useState(false)
  const [errorProductExist, setErrorProductExist] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingStory, setIsCreatingStory] = useState(false)

  const [alert, setAlert] = useState({
    show: false,
    alertType: "",
    message: ""
  })
  const getOptions = async () => {
    try {
      setIsLoading(true)
      const { data } = await storiesRequest.filterProducts(currentCategoryId)
      setOptions(data.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setAlert({ ...alert, show: false })
  }
  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    if (redirect) {
      history.push("/stories")
    }
  }, [redirect])
  const handleCreateStories = async () => {
    if (!storiesProductsFiltredByCategory.length) {
      setAlert({show: true, alertType: "error", message:"Você deve inserir produtos primeiro."})
      return;
    }
    const body = {
      category_id: currentCategoryId,
      story_products: storiesProductsFiltredByCategory
    }
    try {
      setIsCreatingStory(true)
      await storiesRequest.createStory(body)
      setAlert({
        show: true,
        alertType: "success",
        message: "Dados salvos com sucesso!"
      })
      setTimeout(() => {
        setRedirect(true)
      }, 2000)
    } catch (err) {
      setAlert({
        show: true,
        alertType: "error",
        message: "Algo deu errado, tente novamente!"
      })
    } finally {
      setIsCreatingStory(false)
    }
  }

  const handleEditStories = async () => {
    const body = {
      story_id: currentStoryId,
      category_id: currentCategoryId,
      story_products: storiesProductsFiltredByCategory
    }
    try {
      setIsCreatingStory(true)
      await storiesRequest.updateStory(body)
      setAlert({
        show: true,
        alertType: "success",
        message: "Dados salvos com sucesso!"
      })
      setTimeout(() => {
        setRedirect(true)
      }, 2000)
    } catch (err) {
      setAlert({
        show: true,
        alertType: "error",
        message: "Algo deu errado, tente novamente!"
      })
    } finally {
      setIsCreatingStory(false)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          {isCreatingStory && (
            <div style={{ position: "fixed", bottom: "100px" }}>
              <CircularProgress color="secondary" />
            </div>
          )}

          <Topo name="Produtos" />
          {isLoading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "40px"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className="infinite-scroll">
              <div style={{ marginBottom: "1rem" }}>
                <Autocomplete
                  id="tags-standard"
                  onChange={(event, newValue) => {
                    const isProductExist = storiesProductsFiltredByCategory.some(
                      existingProduct =>
                        existingProduct.product_id === newValue?.product_id
                    )
                    if (isProductExist) {
                      setErrorProductExist(true)
                      return
                    }
                    if (newValue && !isProductExist) {
                      setStoriesProductsFiltredByCategory(prevList => [
                        ...prevList,
                        newValue
                      ])
                      setErrorProductExist(false)
                    }
                  }}
                  options={options}
                  getOptionLabel={option => option.product_title}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Produtos"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={props.product_image}
                          alt={props.product_title}
                          style={{ width: 50, marginRight: 10 }}
                        />
                        {props.product_title}
                      </div>
                    </li>
                  )}
                />
                <h3>Organize abaixo a ordem de exibição:</h3>
                {errorProductExist && (
                  <p style={errorStyle}>Esse produto já está selecionado.</p>
                )}
              </div>
              <div className="infinite-scroll">
                <ProductsList
                  categoryId={currentCategoryId}
                  storyId={currentStoryId}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomAlert
                  type={alert.alertType}
                  alertText={alert.message}
                  show={alert.show}
                  close={handleClose}
                />
              </div>
            </div>
          )}
          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            <Button
              onClick={() =>
                currentStoryId ? handleEditStories() : handleCreateStories()
              }
              style={{
                color: "#fff",
                minWidth: "100%",
                height: "50px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                marginTop: "5px",
                marginBottom: "15px",
                textTransform: "none",
                backgroundColor: "#EA3458"
              }}
              type="submit"
              variant="contained"
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
