import { Button, Checkbox, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import TopVariationType from "../../Componentes/TopVariationType"
import { useProduct } from "../../ContextAPI/productContext"
import conexao from "../../Services/api"
import produtosRequest from "../../Services/api/produtos"
import { useStyles } from "./style"

export default function SelectVariationType() {
  const { currentProduct, setCurrentProduct, isEdit } = useProduct()

  const classes = useStyles()
  const history = useHistory()

  const [variationTypeList, setVariationTypeList] = useState([])
  const [checkedVariatonType, setCheckedVariationType] = useState([])
  const [loadPage, setLoadPage] = useState(false)
  const [showModalAlert, setShowModalAlert] = useState(false)

  useEffect(() => {
    getVariationType()
  }, [])
  useEffect(() => {
    if (isEdit && !!currentProduct) {
      setCheckedVariationType(currentProduct.variation_type_selected)
    }
  }, [currentProduct])

  async function getVariationType() {
    const variationTypeListRes = await conexao.get("/variation/type/getall")

    setVariationTypeList(variationTypeListRes.data.data)
    setLoadPage(false)
  }

  function getCheck(e, item) {
    const hasVariationType = checkedVariatonType.find(
      el => el.id_variation_type === item.id
    )
    if (hasVariationType === undefined) {
      if (limitCheck()) {
        return
      }
      setCheckedVariationType(oldState => {
        return [...oldState, { id_variation_type: item.id, name: item.name }]
      })
    } else {
      setCheckedVariationType(oldState =>
        oldState.filter(el => el.id_variation_type !== item.id)
      )
    }
  }

  function limitCheck() {
    const countList = checkedVariatonType.length
    if (countList >= 3) {
      return true
    }
    return false
  }

  async function handleSave() {
    setCurrentProduct(oldState => {
      return { ...oldState, variation_type_selected: checkedVariatonType }
    })

    const body = {
      product_id: currentProduct.id,
      title: currentProduct.title,
      description: currentProduct.description ? currentProduct.description : null,
      price: Number(currentProduct.price),
      product_banner: currentProduct.product_banner,
      product_datasheet: currentProduct.product_datasheet,
      codebar: Number(currentProduct.codebar),
      promotional_price:
        currentProduct.promotional_price !== undefined &&
        currentProduct.promotional_price !== null &&
        String(currentProduct.promotional_price).length
          ? Number(currentProduct.promotional_price)
          : null,
      quantity: currentProduct.quantity ? currentProduct.quantity : null,
      categories: currentProduct.categories ? currentProduct.categories : [],
      show_product: Number(
        currentProduct.show_product === true || currentProduct.show_product === 1
          ? 1
          : 0
      ),
      images: orderImages(),
      variation_type_selected: checkedVariatonType || [],
      sku: currentProduct.sku ? currentProduct.sku : null,
      // codebar: currentProduct.codebar ? currentProduct.codebar : null,
      dimension: {
        height: currentProduct.dimension.height
          ? Number(String(currentProduct.dimension.height).replace(/,/g, "."))
          : null,
        width: currentProduct.dimension.width
          ? Number(String(currentProduct.dimension.width).replace(/,/g, "."))
          : null,
        length: currentProduct.dimension.length
          ? Number(String(currentProduct.dimension.length).replace(/,/g, "."))
          : null,
        weight: currentProduct.dimension.weight
          ? Number(String(currentProduct.dimension.weight).replace(/,/g, "."))
          : null
      }
    }

    const productRes = await produtosRequest.put(currentProduct.id, body)

    if (productRes) {
      setShowModalAlert(true)
      setTimeout(() => history.goBack(), 1500)
    }
  }

  /* ORDER IMAGES BEFORE SAVE */
  function orderImages(fotosArray) {
    let orderedImagesArray = []
    if (isEdit && fotosArray) {
      orderedImagesArray = fotosArray.map((image, index) => {
        image.order = index + 1
        image.dndID = index + 1
        return image
      })
      return orderedImagesArray
    }
    orderedImagesArray = currentProduct.images.map((image, index) => {
      image.order = index + 1
      image.dndID = index + 1
      return image
    })
    return orderedImagesArray
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <TopVariationType
            handleBack={() => history.goBack()}
            name="Variaçoes do produto"
          />
          <div className="infinite-scroll">
            {loadPage ? (
              <div
                style={{
                  backgroundColor: " #F9F9F9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
                <p className={classes.headerInfos}>
                  Marque até 3 tipos de variações para utilizar nesse produto:
                </p>
                {variationTypeList.map((item, index) => {
                  return (
                    <>
                      <div className={classes.rowType}>
                        <div className={classes.typeCheckbox}>
                          <Checkbox
                            id={item.id}
                            checked={
                              checkedVariatonType.find(
                                el => el.id_variation_type === item.id
                              ) !== undefined
                            }
                            name={item.name}
                            onChange={e => getCheck(e, item)}
                          />{" "}
                        </div>

                        <div className={classes.textInfo}>
                          <div className={classes.typeText}>{item.name}</div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </>
            )}
          </div>
          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Button
              className={classes.btnFooterPink}
              onClick={() => handleSave()}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Salvar e sair
            </Button>
          </div>
        </div>
      </div>
      {/* ALERT COMPONENT */}
      {showModalAlert ? (
        <CustomAlert
          type="success"
          alertText="Tipos de variações salvas com sucesso!"
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      ) : (
        ""
      )}
    </>
  )
}
