import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
  },

  bodySection: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  pagesContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    marginLeft: "5px",

    "& span": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#EA3458"
    },

    "& svg": {
      marginRight: "21px"
    }
  },

  pagesContentBePro: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",

    "& span": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF"
    },

    "& div": {
      backgroundColor: "#EA3458",
      width: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px"
    },

    "& svg": {
      marginRight: "21px"
    }
  },

  footerContainer: {
    display: "flex",
    margin: "10px 0 20px 20px",
    alignSelf: "flex-start",

    "& div": {
      display: "flex"
    },

    "& span": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "16.5px",
      color: "#D62B2B"
    }
  }
}))
