import { Button, Dialog } from "@material-ui/core"
import React, { useEffect } from "react"

import { globalStyles } from "../../../CssGlobal"
import { button } from "../../../Inputs/TextFild"
import useStyles from "./styles"
import CloseIcon from "@material-ui/icons/Close"

import usuarioFree from "../../../../Assets/img/usuario-free.png"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import { ColorPicker, Hue, Saturation } from "react-color-palette"
import { useSettings } from "../../../../ContextAPI/settingsContext"

export default function ModalColorPicker({ isOpen, handleClose, setColor, color }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const { handlePrimaryColor } = useSettings()

  useEffect(() => {
    if (color.hex) {
      handlePrimaryColor(color.hex)
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.PlanosAlertaModal}
    >
      <div className={classes.PlanosAlertaModalHeader}>
        <h2 style={{ fontSize: "24px", color: "#EA3458" }}>Cor personalizada </h2>
        <button type="button" onClick={handleClose}>
          <CloseIcon
            style={{
              fontSize: "35px",
              color: "#EA3458",
              cursor: "pointer",
              minWidth: "30px",
              padding: "7px"
            }}
          />
        </button>
      </div>
      <div>
        <div style={{ width: "300px", padding: "0px 20px 10px 20px" }}>
          <ColorPicker
            hideInput={["rgb", "hsv"]}
            hideAlpha={true}
            color={color}
            onChange={setColor}
          />
        </div>
      </div>

      <Button
        onClick={() => {
          handlePrimaryColor(color.hex)
          handleClose()
        }}
        className={classes.isOkBtn}
        variant="contained"
      >
        Selecionar
      </Button>
    </Dialog>
  )
}
