import React, { useEffect, useState } from "react"
import ColorSelect from "../ColorSelect"
import { useStyles } from "./style"
import { useColor } from "react-color-palette"
// eslint-disable-next-line import/no-unresolved
import "react-color-palette/css"
import ModalColorPicker from "../Components/ModalColorPicker"
import ModalColor from "../Components/ModalColor"
import EditIcon from "@material-ui/icons/Edit"
import { useSettings } from "../../../ContextAPI/settingsContext"
import CheckIcon from "@material-ui/icons/Check"

const ColorSelectContainer = () => {
  const { primaryColor, secondaryColor, handlePrimaryColor, handleSecondaryColor } =
    useSettings()
  const classes = useStyles()
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false)
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false)
  const [selectedPrimaryColor, setSelectedPrimaryColor] = useColor(
    primaryColor?.replace("NaN", "")
  )
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useColor(
    secondaryColor?.replace("NaN", "")
  )
  const handlePrimaryColorClick = () => {
    setShowPrimaryColorPicker(!showPrimaryColorPicker)
  }

  const handleSecondaryColorClick = () => {
    setShowSecondaryColorPicker(!showSecondaryColorPicker)
  }

  return (
    <div className={classes.ColorSelectContainer}>
      <div>
        <h3>Cores primárias</h3>
        <ColorSelect
          type="primary"
          setSelectedPrimaryColor={setSelectedPrimaryColor}
        />
      </div>
      <div>
        <div className={classes.TitleCustom}>
          <p className={classes.SubtitleCustom}>Personalizada: </p>
          <div
            className={classes.ContainerPrimary}
            onClick={handlePrimaryColorClick}
            style={{
              backgroundColor: selectedPrimaryColor.hex
                ? selectedPrimaryColor.hex
                : primaryColor,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <EditIcon sx={{ color: "white" }} />
          </div>
        </div>
      </div>
      <div>
        <h3>Cores secundárias</h3>
        <ColorSelect
          type="secondary"
          setSelectedSecondaryColor={setSelectedSecondaryColor}
        />
      </div>
      <div>
        <div className={classes.TitleCustom}>
          {" "}
          <p className={classes.SubtitleCustom}>Personalizada: </p>
          <div
            className={classes.ContainerSecundary}
            onClick={handleSecondaryColorClick}
            style={{
              width: "45px",
              height: "45px",
              backgroundColor: selectedSecondaryColor.hex
                ? selectedSecondaryColor.hex
                : secondaryColor,
              borderRadius: "10px",
              margin: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <EditIcon sx={{ color: "#fff" }} />
          </div>
        </div>
      </div>
      <ModalColorPicker
        isOpen={showPrimaryColorPicker}
        handleClose={() => setShowPrimaryColorPicker(false)}
        setColor={setSelectedPrimaryColor}
        color={selectedPrimaryColor}
      />

      <ModalColor
        isOpen={showSecondaryColorPicker}
        handleClose={() => setShowSecondaryColorPicker(false)}
        setColor2={setSelectedSecondaryColor}
        color2={selectedSecondaryColor}
      />
    </div>
  )
}

export default ColorSelectContainer
