import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import Switch from "@material-ui/core/Switch"
import { button } from "../../Componentes/Inputs/TextFild"
import { useEffect, useState } from "react"
import { CircularProgress, TextField } from "@material-ui/core"
import complementsModoManutencao from "../../Services/api/modomanutencao"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function ModoDemonstração() {
  const [enableInputs, setEnableInputs] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const handleRedirect = () => {
    history.replace("/personalizar-lojinha/complementos")
    history.goBack()
  }

  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula"
  ]
  async function getPlaceHolders(params) {
    const { data } = await complementsModoManutencao.get()
    setEnableInputs(data.data.complements_placeholders_status)
  }
  function sendData() {
    setLoading(true)
    try {
      complementsModoManutencao.put({
        complements_placeholders_status: enableInputs
      })
      setAlertType("success")
      setAlertMessage("Atualizado com sucesso!")
      setShowModalAlert(true)
      setTimeout(() => {
        handleRedirect()
      }, 1000)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, "1000")
    }
  }

  useEffect(() => {
    getPlaceHolders()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Modo demonstração" />

          <div className={classes.ContainerForms}>
            <div className={classes.InputBox}>
              <div className={classes.InputLabel}>
                <label>Ativar</label>
                <Switch
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={() => {
                    setEnableInputs(!enableInputs)
                  }}
                  checked={enableInputs}
                />
              </div>

              <p className={classes.Subtitle}>
                O modo "Demonstração" apresenta espaços vazios em sua loja virtual,
                prontos para serem preenchidos. Na página da loja, ao ativar essa
                opção, a loja exibirá blocos cinzas que oferecem uma demonstração
                visual de como sua loja pode ser apresentada ao utilizar todas as
                configurações disponíveis pela Uppy. Caso decida desativar essa opção
                antes de explorar todos os recursos avançados oferecidos pela nossa
                ferramenta, a aparência da sua loja pode não aproveitar ao máximo as
                funcionalidades premium proporcionadas pela Uppy.
              </p>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          {button({
            funcao: sendData,
            name: loading ? (
              <CircularProgress style={{ color: "#fff" }} />
            ) : (
              "Salvar alterações"
            )
          })}
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </div>
  )
}
