import React, { useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "./routes"
import "./style.css"
import "elt-react-credit-cards/es/styles-compiled.css"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
/* imports */

/* contexts */
import { DeviceProvider } from "./ContextAPI/deviceContext"
import { SettingsProvider } from "./ContextAPI/settingsContext"
import { ProductProvider } from "./ContextAPI/productContext"
import ContextProvider from "./ContextAPI"
import { Container } from "@material-ui/core"
import BannerAppDesktop from "./Componentes/BannerAppDesktop"
import MobileDeviceText from "./Componentes/MobileDeviceText"
import { CookiesProvider } from "react-cookie"
import BannerAppHeaderPlan from "./Componentes/BannerAppHeaderPlan"

const theme = createTheme({
  palette: {
    primary: {
      main: "#051626"
    },
    secondary: {
      main: "#EA3458"
    }
  }
})

export default function App() {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if(sessionStorage.getItem('closeBanner') === 'true') {
      setDisabled(true)
    }
  },[])
  return (
    <ThemeProvider theme={theme}>
      <DeviceProvider>
        <CookiesProvider>
          <SettingsProvider>
            <ProductProvider>
              <ContextProvider>
                <div
                  style={{
                    backgroundColor: "#F9F9F9",
                    height: "100%"
                  }}
                >
                  {window.location.pathname !== "/login" &&
                  window.location.pathname !== "/cadastro" ? (
                    <BannerAppDesktop
                      callback={value => {
                        setDisabled(value)
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <MobileDeviceText />
                  <Container
                    maxWidth="sm"
                    style={{
                      padding: "0px",
                      height:
                        disabled === false &&
                        window.innerWidth >= 499 &&
                        window.location.pathname !== "/login" &&
                        window.location.pathname !== "/cadastro"
                          ? "calc(100% - 80px)"
                          : "100%"
                    }}
                  >
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </Container>
                </div>
              </ContextProvider>
            </ProductProvider>
          </SettingsProvider>
        </CookiesProvider>
      </DeviceProvider>
    </ThemeProvider>
  )
}
