import {
  CircularProgress,
  FormControl,
  InputAdornment,
  NativeSelect,
  TextField
} from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import Switch from "@material-ui/core/Switch"
import { withStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import { current } from "../../../Componentes/validates/Validate"
import { StateGlobal } from "../../../ContextAPI/index"
import api from "../../../Services/api"
import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export default function FreteFixo() {
  const classes = useStyles()

  const {
    fixedShippingCitiesData,
    setFixedShippingCitiesData,
    fixedShippingStatesData,
    setFixedShippingStatesData,
    fixedShippingData,
    setFixedShippingData,
    firstTimeFixed,
    setFirstTimeFixed,
    enableFixedDelivery,
    setEnableFixedDelivery,
    hasContext
  } = StateGlobal()
  const [enableFixedByPrice, setEnableFixedByPrice] = useState(false)
  const [enableFixedByState, setEnableFixedByState] = useState(false)
  const [enableFixedByCity, setEnableFixedByCity] = useState(false)
  const [emptyRequired, setEmptyRequired] = useState(false)
  const shopId = localStorage.getItem("id")
  const [loading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const history = useHistory()

  const getInformations = async () => {
    await api.get(`/lojinha/shipping/get/${shopId}/fixed`).then(res => {
      const currentInformations = res.data.data
      if (currentInformations === null) {
        setFirstTimeFixed(true)
        setLoading(false)
      } else {
        let new_time_type
        if (currentInformations.shipping_data.average_time_type === "days") {
          new_time_type = "DIAS"
        } else if (currentInformations.shipping_data.average_time_type === "hours") {
          new_time_type = "HORAS"
        } else {
          new_time_type = "MINUTOS"
        }
        const priceDataGet = currentInformations.shipping_data.value || 0
        const time_deliveryDataGet =
          currentInformations.shipping_data.average_time || ""
        const time_typeDataGet = new_time_type || ""
        const state_filterDataGet =
          currentInformations.shipping_data.state_filter || []
        const state_city_filterDataGet =
          currentInformations.shipping_data.state_city_filter || []

        setFixedShippingData({
          value: priceDataGet,
          time_delivery: time_deliveryDataGet,
          time_type: time_typeDataGet,
          state_filter: state_filterDataGet,
          state_city_filter: state_city_filterDataGet,
          shipping_id: currentInformations.id
        })

        if (state_city_filterDataGet.length > 0) {
          setEnableFixedByCity(true)
        }

        if (state_filterDataGet.length > 0) {
          setEnableFixedByState(true)
        }
        if (!fixedShippingStatesData.length && !hasContext) {
          setFixedShippingStatesData(state_filterDataGet)
        }

        if (!fixedShippingCitiesData.length && !hasContext) {
          setFixedShippingCitiesData(state_city_filterDataGet)
        }

        setEnableFixedDelivery(currentInformations.shipping_active)
      }

      setLoading(false)
    })
  }

  const handleBackward = () => {
    setTimeout(() => {
      clearContext()
      history.goBack()
    }, 2500)
  }

  const clearContext = () => {
    setEnableFixedDelivery(false)
    setFixedShippingCitiesData([])
    setFixedShippingStatesData([])
  }

  async function dataSave() {
    let new_time_type

    if (fixedShippingData?.time_type === "DIAS") {
      new_time_type = "days"
    } else if (fixedShippingData?.time_type === "HORAS") {
      new_time_type = "hours"
    } else {
      new_time_type = "minutes"
    }

    const valueIsValid = fixedShippingData?.value !== 0 && fixedShippingData?.value !== null
    const timeIsValid = fixedShippingData?.time_delivery !== "" && fixedShippingData?.time_delivery !== 0 && fixedShippingData?.time_delivery !== null && fixedShippingData?.time_delivery !== undefined

    if (!valueIsValid || !timeIsValid) {
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("O valor do frete ou o tempo de entrega não podem ser zero.")
      return
    }

    const timeDeliveryAsString = fixedShippingData?.time_delivery.toString().replace(/^0+/, '');

    const bodyCreate = {
      lojinha_id: shopId,
      shipping_type: "fixed_shipping",
      shipping_active: enableFixedDelivery ? 1 : 0,
      shipping_data: {
        value: fixedShippingData?.value === 0 ? 0 : fixedShippingData?.value,
        average_time: timeDeliveryAsString,
        average_time_type: new_time_type,
        state_filter: enableFixedByState ? fixedShippingStatesData : [],
        state_city_filter: enableFixedByCity ? fixedShippingCitiesData : []
      }
    }

    const bodyUpdate = {
      lojinha_id: shopId,
      shipping_active: enableFixedDelivery ? 1 : 0,
      shipping_type: "fixed_shipping",
      shipping_data: {
        value: fixedShippingData?.value === 0 ? 0 : fixedShippingData?.value,
        average_time: timeDeliveryAsString,
        average_time_type: new_time_type,
        state_filter: enableFixedByState ? fixedShippingStatesData : [],
        state_city_filter: enableFixedByCity ? fixedShippingCitiesData : []
      }
    }

    if (
      bodyCreate.shipping_data.average_time === null ||
      bodyCreate.shipping_data.average_time === "" ||
      bodyUpdate.shipping_data.average_time === null ||
      bodyUpdate.shipping_data.average_time === ""
    ) {
      setEmptyRequired(true)
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("Preencha os campos obrigatórios")
      return
    }

    if (firstTimeFixed === true) {
      let resCreate = await api.post(`/lojinha/shipping/create`, bodyCreate)
      if (resCreate.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        setFirstTimeFixed(false)
        handleBackward()
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    } else {
      let res = await api.put(
        `/lojinha/shipping/update/${fixedShippingData.shipping_id}`,
        bodyUpdate
      )
      if (res.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        handleBackward()
      } else if (
        res.data.error === true &&
        res.data.message === "Existe apenas um checkout ativo!"
      ) {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Deixe pelo menos 1 frete ativado!")
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    }
  }

  function selectCitiesPlaceholder(cities) {
    let newArr = []

    cities.forEach(city => {
      newArr.push(...city.cities)
    })

    return newArr.join(", ")
  }

  useEffect(() => {
    getInformations()
  }, [])

  useEffect(() => {
    setFixedShippingData(oldState => ({
      ...oldState,
      state_city_filter: fixedShippingCitiesData
    }))
    if (fixedShippingCitiesData.length > 0) {
      setEnableFixedByCity(true)
    }
  }, [fixedShippingCitiesData])

  useEffect(() => {
    setFixedShippingData(oldState => ({
      ...oldState,
      state_filter: fixedShippingStatesData
    }))
    if (fixedShippingStatesData.length > 0) {
      setEnableFixedByState(true)
    }
  }, [fixedShippingStatesData])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Frete Fixo" />
            <CustomAlert
              type={alertType}
              alertText={alertMsg}
              show={showAlert}
              close={() => setShowAlert(false)}
            />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div className={classes.toggledTitle}>
                  <h2>Habilitar opção de frete fixo</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={() => {
                      setEnableFixedDelivery(!enableFixedDelivery)
                    }}
                    checked={enableFixedDelivery}
                  />
                </div>

                {enableFixedDelivery ? (
                  <form>
                    <div className={classes.divbox}>
                      <div style={{ marginBottom: "20px" }}>
                        <h4
                          style={{
                            margin: "10px 0",
                            fontWeight: "500",
                            color: "#555555"
                          }}
                        >
                          Valor fixo a ser cobrado no frete:
                        </h4>
                        <TextField
                          name="title"
                          onChange={e => {
                            setFixedShippingData({
                              ...fixedShippingData,
                              value: current(e.target.value.substring(0, 20))
                            })
                          }}
                          size="small"
                          variant="outlined"
                          type="tel"
                          color="secondary"
                          value={
                            fixedShippingData !== null &&
                            fixedShippingData?.value &&
                            fixedShippingData?.value !== "0,00"
                              ? new Intl.NumberFormat("pt-BR", {
                                  minimumFractionDigits: 2
                                }).format(fixedShippingData.value)
                              : "0,00"
                          }
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            )
                          }}
                          style={{ backgroundColor: "#fff" }}
                        />
                      </div>
                      <div>
                        <h4
                          style={{
                            margin: "10px 0",
                            fontWeight: "500",
                            color: "#555555"
                          }}
                        >
                          Tempo estimado para entrega:
                        </h4>
                        <div className={classes.switch}>
                          <TextField
                            name="shipping"
                            size="small"
                            color="secondary"
                            variant="outlined"
                            type="tel"
                            helperText={
                              emptyRequired === true ? (
                                <p
                                  style={{
                                    color: "red",
                                    margin: 0,
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  campo obrigatório
                                </p>
                              ) : (
                                ""
                              )
                            }
                            onChange={e => {
                              setFixedShippingData({
                                ...fixedShippingData,
                                time_delivery: e.target.value.replace(/\D/g, "")
                              })
                            }}
                            value={fixedShippingData?.time_delivery || ""}
                            style={{ backgroundColor: "#fff", width: "65%" }}
                          />
                          <div className={classes.dropdown}>
                            <FormControl>
                              <NativeSelect
                                id="demo-customized-select-native"
                                value={fixedShippingData?.time_type || ""}
                                onChange={e => {
                                  setFixedShippingData({
                                    ...fixedShippingData,
                                    time_type: e.target.value
                                  })
                                }}
                                input={<BootstrapInput />}
                              >
                                <option>MINUTOS</option>
                                <option>HORAS</option>
                                <option>DIAS</option>
                              </NativeSelect>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={classes.divbox}>
                        <div className={classes.switch}>
                          <h2>Frete fixo por região/estado?</h2>
                          <Switch
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onClick={() => {
                              setEnableFixedByState(!enableFixedByState)
                            }}
                            checked={enableFixedByState}
                          />
                        </div>

                        {enableFixedByState ? (
                          <>
                            <h4
                              style={{
                                margin: "10px 0",
                                fontWeight: "500",
                                color: "#555555"
                              }}
                            >
                              Estados
                            </h4>
                            <Link to="/selecionar-estado?from=fixedShipping">
                              <TextField
                                name="states"
                                type="text"
                                value={
                                  fixedShippingStatesData?.join(", ") ||
                                  "Selecionar estados"
                                }
                                size="medium"
                                disabled
                                placeholder="Selecionar estados"
                                variant="outlined"
                                color="secondary"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  backgroundColor: "#fff",
                                  cursor: "pointer"
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <ArrowForwardIosIcon
                                        style={{ color: "#A4A4A4" }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                              ></TextField>
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div>
                        <div className={classes.divbox}>
                          <div className={classes.switch}>
                            <h2>Frete fixo por cidade?</h2>
                            <Switch
                              inputProps={{ "aria-label": "secondary checkbox" }}
                              onClick={() => {
                                setEnableFixedByCity(!enableFixedByCity)
                              }}
                              checked={enableFixedByCity}
                            />
                          </div>

                          {enableFixedByCity ? (
                            <>
                              <h4
                                style={{
                                  margin: "10px 0",
                                  fontWeight: "500",
                                  color: "#555555"
                                }}
                              >
                                Cidade
                              </h4>
                              <Link to="/selecionar-cidade?from=fixedShipping">
                                <TextField
                                  name="states"
                                  type="text"
                                  value={
                                    fixedShippingCitiesData?.length > 0
                                      ? selectCitiesPlaceholder(
                                          fixedShippingCitiesData
                                        )
                                      : "Selecionar cidades"
                                  }
                                  size="medium"
                                  disabled
                                  placeholder="Selecionar cidades"
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    cursor: "pointer"
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <ArrowForwardIosIcon
                                          style={{ color: "#A4A4A4" }}
                                        />
                                      </InputAdornment>
                                    )
                                  }}
                                ></TextField>
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

            {button({
              name: "Salvar Alterações",
              funcao: dataSave
            })}
          </div>
        </div>
      </div>
    </>
  )
}
