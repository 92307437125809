import { useCallback, useEffect, useState } from "react"

import { Button, TextField } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import { useHistory } from "react-router-dom"
import Topo from "../../Componentes/TopCategorias"

import CircularProgress from "@material-ui/core/CircularProgress"
import clsx from "clsx"
import engineIcon from "../../Assets/svg/engineIcon.svg"
import syncIcon from "../../Assets/svg/syncIcon.svg"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { StateGlobal } from "../../ContextAPI"
import lojinhaConfigsRequest from "../../Services/api/lojinhaConfigs"

import api from "../../Services/api"
import { useStyles } from "./style"

function MelhorEnvio() {
  const classes = useStyles()
  const history = useHistory()

  let payhowToken = localStorage.getItem("payhow_token")
  let payhowID = localStorage.getItem("payhow_id")

  const {
    bestShippingIntegrationInfos,
    setBestShippingIntegrationInfos,
    bestShippingSetupFormData,
    setBestShippingSetupFormData,
    syncFormData,
    setSyncFormData,
    disableSaveButton,
    setDisableSaveButton
  } = StateGlobal()

  const [showAlert, setShowAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [noCompany, setNoCompany] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [dimensionInfo, setDimensionInfo] = useState()
  const shopId = localStorage.getItem("id")

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    handleSyncIntegration()
  }, [bestShippingSetupFormData.me_token])

  const fetchData = useCallback(async () => {
    setLoading(true)
    const dimensionData = await api.get(`product/check/dimesion/${shopId}`)
    setDimensionInfo(dimensionData.data.data)

    const { data } = await lojinhaConfigsRequest.bestShippingGetIntegrationInfos(
      payhowID,
      payhowToken
    )

    let me_data = data.find(item => item.name === "Melhor Envio")

    if (Array.isArray(me_data.credentials)) {
      setLoading(false)
      return
    }

    if (syncFormData) {
      setBestShippingSetupFormData({
        enable: me_data.enable,
        ...me_data.credentials
      })
    }

    setLoading(false)
    setDisableSaveButton(false)
    setSyncFormData(true)
  }, [])

  async function handleSyncIntegration() {
    if (bestShippingSetupFormData.me_token) {
      setSyncLoading(true)
      try {
        const { data } = await lojinhaConfigsRequest.bestShippingAuthentication(
          bestShippingSetupFormData.me_token
        )
        setBestShippingIntegrationInfos(prevState => ({
          ...prevState,
          ...data
        }))

        if (
          bestShippingIntegrationInfos.companies === null ||
          bestShippingIntegrationInfos.companies === undefined
        ) {
          setNoCompany(false)

          setAlertMessage(
            "Erro ao integrar Melhor Envio! Entre em contato com nosso suporte."
          )
          setAlertType("error")
          setShowAlertOk(true)
        }
      } catch (error) {
        console.log("error", error)
        setAlertMessage(
          "Erro ao integrar Melhor Envio! Entre em contato com nosso suporte."
        )
        setAlertType("error")
        setShowAlertOk(true)
      } finally {
        setSyncLoading(false)
      }
    }
  }

  async function handleSaveButton() {
    let payload = {
      ...bestShippingSetupFormData,
      me_jadlog_id: bestShippingSetupFormData.me_jadlog_id,
      me_correios_id: bestShippingSetupFormData.me_correios_id,
      me_azul_cargo_id: bestShippingSetupFormData.me_azul_cargo_id,
      me_latam_cargo_id: bestShippingSetupFormData.me_latam_cargo_id,
      integration_slug: "melhor-envio",
      company_id: localStorage.getItem("payhow_id")
    }

    try {
      await lojinhaConfigsRequest
        .bestShippingCreateIntegration(payload, payhowToken)
        .finally(() => {
          setTimeout(() => {
            history.goBack()
          }, [1500])
          setAlertMessage("Configurações salvas com sucesso!")
          setAlertType("success")
          setShowAlertOk(true)
        })
    } catch (e) {
      console.log("error", e)
    }
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Integração com Melhor Envio" noPadding />
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              minHeight: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <div className={`${classes.pagseguroOrders}  infinite-scroll`}>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <p>Habilitar integração com Melhor Envio</p>
                <Switch
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={e => {
                    setBestShippingSetupFormData(prevState => ({
                      ...prevState,
                      enable: e.target.checked
                    }))
                  }}
                  checked={
                    bestShippingSetupFormData.enable ||
                    bestShippingSetupFormData.enable === 1
                  }
                />
              </div>

              {bestShippingSetupFormData.enable ? (
                <>
                  <div className={`${classes.pagseguroOrders}__token`}>
                    <p>Cole abaixo o token de integração:</p>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={8}
                      fullWidth
                      color="secondary"
                      value={bestShippingSetupFormData.me_token}
                      onChange={e => {
                        setBestShippingSetupFormData(prevState => ({
                          ...prevState,
                          me_token: e.target.value
                        }))
                      }}
                    />

                    <div className={`${classes.pagseguroOrders}__tokenStatus`}>
                      <p>
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            backgroundColor:
                              dimensionInfo === false
                                ? "#ff9800"
                                : bestShippingIntegrationInfos.authenticated
                                ? "#63BD38"
                                : "#EC413D",
                            borderRadius: "50%",
                            marginRight: "5px"
                          }}
                        />
                        Status da integração:{" "}
                        <strong>
                          {dimensionInfo === false
                            ? "Existem produtos sem dimensões definidas!"
                            : bestShippingIntegrationInfos?.authenticated
                            ? "Conectado!"
                            : "Inativo"}
                        </strong>
                      </p>

                      <button onClick={() => handleSyncIntegration()}>
                        <img
                          src={syncIcon}
                          alt="sync-icon"
                          className={syncLoading && classes.rotateAnimation}
                        />
                      </button>
                    </div>
                  </div>

                  {bestShippingIntegrationInfos?.authenticated &&
                  !syncLoading &&
                  bestShippingSetupFormData.me_token ? (
                    <button
                      onClick={() => history.push("/configuracao-melhor-envio")}
                      className={`${classes.pagseguroOrders}__integrationSetup`}
                    >
                      <img src={engineIcon} alt="engine-icon" />
                      Configurações do Melhor Envio
                    </button>
                  ) : (
                    ""
                  )}

                  <div className={`${classes.pagseguroOrders}__information`}>
                    <p>
                      Não sabe como obter as informações do Melhor Envio?
                      <a
                        href="https://blog.uppyapp.com.br/2022/09/como-integrar-melhor-envio-uppy-app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        clique aqui
                      </a>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="footer-column">
              <hr style={{ width: "100%", backgroundColor: "#ffffff0f" }} />

              <Button
                className={clsx({
                  [classes.btnSave]: true,
                  [classes.disabledButton]: disableSaveButton
                })}
                fullWidth
                onClick={() => handleSaveButton()}
                disabled={disableSaveButton}
              >
                Salvar informações
              </Button>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </div>
    </div>
  )
}

export default MelhorEnvio
