import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React from "react"
import { useHistory } from "react-router-dom"
import { globalStyles } from "../../Componentes/CssGlobal"
import { useSettings } from "../../ContextAPI/settingsContext"

import { useStyles } from "./style"

import BannerSejaPro from "../../Componentes/BannerSejaPro"
import Topo from "../../Componentes/TopCategorias"

export default function Home() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Gerenciar Produtos" />
          </div>
          <div className="infinite-scroll">
            <div
              className={classes.boxContainer}
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/meus-produtos")
              }}
            >
              <div className={classes.Box}>
                <div className={classesGlobal.fonteTitulo}>Gerenciar Produtos</div>
                <div className={classesGlobal.fonteSubtitulo}>
                  Gerencie seus produtos, variações e estoque
                </div>
              </div>
              <ArrowForwardIosIcon
                fontSize="small"
                color="disabled"
                role="presentation"
              />
            </div>
            <div
              className={classes.boxContainer}
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/novidades"
              }}
            >
              <div className={classes.Box}>
                <div className={classesGlobal.fonteTitulo}>
                  Listagem de produtos em novidades
                </div>
                <div className={classesGlobal.fonteSubtitulo}>
                  Organize seus produtos em destaque por relevância
                </div>
              </div>
              <ArrowForwardIosIcon
                fontSize="small"
                color="disabled"
                role="presentation"
              />
            </div>

            <div
              className={classes.boxContainer}
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/mais-vendidos"
              }}
            >
              <div className={classes.Box}>
                <div className={classesGlobal.fonteTitulo}>
                  Listagem de produtos mais vendidos
                </div>
                <div className={classesGlobal.fonteSubtitulo}>
                  Organize seus produtos mais vendidos por relevância
                </div>
              </div>
              <ArrowForwardIosIcon
                fontSize="small"
                color="disabled"
                role="presentation"
              />
            </div>
            {hasPro ? (
              ""
            ) : (
              <BannerSejaPro bannerId="banner-menu-gerenciar-produtos" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
