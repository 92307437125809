import CloseIcon from "@material-ui/icons/Close"
import { useStyles } from "./style"
import logoBanner from "../../Assets/img/logoBanner.png"
import btnApple from "../../Assets/img/btnApple.png"
import btnAndroid from "../../Assets/img/btnAndroid.png"
import { useEffect, useState } from "react"
import { useDevice } from "../../ContextAPI/deviceContext"

export default function BannerAppDesktop({ callback }) {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(false)
  const { isMobileDevice, checkDevice } = useDevice()

  useEffect(() => {
    if (sessionStorage.getItem("closeBanner") === "true") {
      setDisabled(true)
    }
  }, [])

  return (
    <>
      {disabled === false &&
      isMobileDevice === false &&
      checkDevice().os !== "iOS" ? (
        <div
          className={
            window.screen.width >= 1920 ? classes.bannerLarge : classes.banner
          }
        >
          <div className={classes.bannerLeftSide}>
            <div>
              <img src={logoBanner} alt="logo" className={classes.imgBanner} />
            </div>
            <div>
              <h2 className={classes.titleBanner}>Já conhece o nosso app?</h2>
              <h3 className={classes.subtitleBanner}>
                Faça o download agora e gerencie sua loja através do nosso
                aplicativo.
              </h3>
            </div>
          </div>
          <div className={classes.bannerRightSide}>
            <div className={classes.btnApple}>
              <a
                href="https://apps.apple.com/br/app/uppy/id1607126536"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnApple} alt="button Apple" height="100%" width="100%" />
              </a>
            </div>
            <div className={classes.btnAndroid}>
              <a
                href="https://play.google.com/store/apps/details?id=com.lionsoft.uppy"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={btnAndroid}
                  alt="button Android"
                  height="100%"
                  width="100%"
                />
              </a>
            </div>
            <div>
              <CloseIcon
                className={classes.closeBtn}
                onClick={() => {
                  setDisabled(true)
                  callback(true)
                  sessionStorage.setItem("closeBanner", true)
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
