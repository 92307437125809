import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
  TextField
} from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { CepInput } from "../../../Componentes/CustomInputs/CEP"
import TopProdutos from "../../../Componentes/TopProdutos"
import { StateGlobal } from "../../../ContextAPI/index"
import lojinhaConfigsRequest from "../../../Services/api/lojinhaConfigs"
import { useStyles } from "./style"

export default function IntegracaoCorreios() {
  const [leaving, setLeaving] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const { address, setAddress } = StateGlobal({})
  const [isEdit, setIsEdit] = useState(null)
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [submit, setSubmit] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [postOfficeTypeChecked, setPostOfficeTypeChecked] = useState([])
  const [enableIntegration, setEnableIntegration] = useState(false)
  const [emptyRequired, setEmptyRequired] = useState({
    CEP: 0,
    address: 0,
    number: 0,
    neighborhood: 0,
    city: 0,
    state: 0
  })
  const shopId = localStorage.getItem("id")

  const searchPostalCode = async postalCode => {
    const replacedCEP = postalCode.replace("-", "").replace("_", "")
    if (replacedCEP.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${postalCode.replace(/\D/g, "")}/json/`)
        .then(res => {
          if (res.data.erro !== true)
            setAddress({
              zip_code: res.data.cep.replace(/\D/g, ""),
              street: res.data.logradouro,
              neighborhood: res.data.bairro,
              city: res.data.localidade,
              state: res.data.uf
            })
        })
    }
  }

  // const handlePayhowCredentials = async () => {
  //   const payhowCredentials = await api.get(`payhow/show/${shopId}`)

  //   if (payhowCredentials) {
  //     setPayhowInfo(payhowCredentials.data.data)
  //   }
  // }

  const payhow_id = localStorage.getItem("payhow_id")
  const token = localStorage.getItem("payhow_token")

  const handleEdit = async () => {
    const postOffice = await lojinhaConfigsRequest.postOfficeGetIntegration(
      payhow_id,
      token
    )

    const withdrawalData = postOffice.data[0]

    if (withdrawalData.enable === 1) {
      setEnableIntegration(true)

      setAddress({
        city: withdrawalData.credentials.shipping.address.city || "",
        complement: withdrawalData.credentials.shipping.address.complement || "",
        neighborhood: withdrawalData.credentials.shipping.address.neighborhood || "",
        number: withdrawalData.credentials.shipping.address.number || "",
        state: withdrawalData.credentials.shipping.address.state || "",
        street: withdrawalData.credentials.shipping.address.street || "",
        zip_code: withdrawalData.credentials.shipping.address.postal_code || ""
      })

      setPostOfficeTypeChecked(withdrawalData.credentials.shipping.correios_type)

      setLoading(false)
    } else {
      setEnableIntegration(false)
      setLoading(false)
    }
    setLoading(false)
  }

  function checkRequiredInputs(body) {
    let errors = [0, 0, 0, 0, 0, 0]
    if (body.postal_code === "") errors[0] = 1
    if (body.street === "") errors[1] = 1
    if (body.number === "") errors[2] = 1
    if (body.neighborhood === "") errors[3] = 1
    if (body.city === "") errors[4] = 1
    if (body.state === "") errors[5] = 1

    setEmptyRequired({
      CEP: errors[0] === 1,
      address: errors[1] === 1,
      number: errors[2] === 1,
      neighborhood: errors[3] === 1,
      city: errors[4] === 1,
      state: errors[5] === 1
    })

    if (errors[0] || errors[1] || errors[2] || errors[3] || errors[4] || errors[5]) {
      setAlertMessage("Preencha os campos obrigatórios.")
      setAlertType("error")
      setShowModalAlert(true)
    }
  }

  async function saveIntegration() {
    const body = {
      company_id: payhow_id,
      integration_slug: "correios",
      street: address.street,
      number: address.number,
      city: address.city,
      state: address.state,
      postal_code: address.zip_code,
      neighborhood: address.neighborhood,
      complement: address.complement,
      correios_type: postOfficeTypeChecked,
      correios_contract: null,
      correios_password: null,
      enable: enableIntegration
    }

    checkRequiredInputs(body)

    lojinhaConfigsRequest
      .postOfficeCreateIntegration(body, token)
      .then(res => {
        setAlertMessage(
          isEdit
            ? "Opção de entrega integrada com sucesso!"
            : "Opção de entrega editada com sucesso!"
        )
        setLoading(false)
        setAlertType("success")
        setShowModalAlert(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error =>
        console.log(
          error,
          setAlertMessage(
            isEdit ? "Erro ao editar integração!" : "Erro ao criar integração!"
          ),
          setAlertType("error"),
          setShowModalAlert(true),
          setLoading(false)
        )
      )
    setLoading(false)
  }

  const handleAddressChange = e => {
    const { name, value } = e.target
    if (name === "zip_code") {
      searchPostalCode(value)
    }
    setAddress(oldState => {
      return { ...oldState, [name]: value }
    })
  }

  const handleChangeChecked = event => {
    const { value, checked } = event.target
    let arr = postOfficeTypeChecked
    let typeAlreadyExists = postOfficeTypeChecked.some(type => type === value)
    if (typeAlreadyExists) {
      arr = arr.filter(i => i !== value)
    } else {
      arr.push(value)
    }
    setPostOfficeTypeChecked([...arr])
  }

  /* const hanldleChecked = () => {

  if (postOfficeTypeChecked.pac === true && postOfficeTypeChecked.sedex === true) {
      setPostOfficeType(["sedex", "pac"])
    } else if (postOfficeTypeChecked.pac === true) {
      setPostOfficeType(["pac"])
    } else if (postOfficeTypeChecked.sedex === true) {
      setPostOfficeType(["sedex"])
    } else if (postOfficeTypeChecked.sedex === false || postOfficeTypeChecked.pac === false) {
      setPostOfficeType([""])
    }

  } */

  /* useEffect(() => {
    hanldleChecked()
  }, [postOfficeTypeChecked]) */

  const handleLeaving = () => {
    history.goBack()
  }

  useEffect(() => {
    // handlePayhowCredentials()
    handleEdit()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div className={classes.header}>
            <TopProdutos
              name="Integração com os Correios"
              handleBack={handleLeaving}
            />
          </div>
          {loading ? (
            <div
              style={{
                backgroundColor: " #f9f9f9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CircularProgress color="secondary" size="40px" />
            </div>
          ) : (
            <div className="infinite-scroll">
              <div className={classes.Slides} style={{ alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <h2>Habilitar integração com os Correios</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    name="integration"
                    onClick={() => {
                      setEnableIntegration(!enableIntegration)
                    }}
                    checked={enableIntegration}
                  />
                </div>
              </div>
              {enableIntegration ? (
                <>
                  <h2>Opções disponíveis</h2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <FormControlLabel
                        name="sedex"
                        value="sedex"
                        control={
                          <Checkbox
                            checked={postOfficeTypeChecked.some(
                              type => type === "sedex"
                            )}
                            onChange={handleChangeChecked}
                            color="secondary"
                          />
                        }
                        label="Sedex"
                        labelPlacement="Sedex"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        name="pac"
                        value="pac"
                        control={
                          <Checkbox
                            checked={postOfficeTypeChecked.some(
                              type => type === "pac"
                            )}
                            color="secondary"
                            onChange={handleChangeChecked}
                          />
                        }
                        label="PAC"
                        labelPlacement="PAC"
                      />
                    </div>
                  </div>
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    Preencha os campos com os dados correspondentes:
                  </h4>
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    CEP do local
                  </h4>
                  <div className={classes.FormCep}>
                    <div>
                      <CepInput
                        name="zip_code"
                        value={address.zip_code}
                        callback={handleAddressChange}
                        submit={submit}
                      />
                    </div>
                  </div>
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    Endereço
                  </h4>
                  <TextField
                    name="street"
                    style={{ backgroundColor: "#fff" }}
                    onChange={e => handleAddressChange(e)}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    /* placeholder="Limite de 30 caracteres" */
                    callback={handleAddressChange}
                    value={address.street}
                    helperText={
                      emptyRequired.address === true ? (
                        <p
                          style={{
                            color: "red",
                            margin: 0,
                            fontSize: "0.8rem"
                          }}
                        >
                          campo obrigatório
                        </p>
                      ) : (
                        ""
                      )
                    }
                  />
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    Número
                  </h4>
                  <TextField
                    name="number"
                    style={{ backgroundColor: "#fff" }}
                    onChange={e => handleAddressChange(e)}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type="number"
                    value={address.number}
                    helperText={
                      emptyRequired.number === true ? (
                        <p
                          style={{
                            color: "red",
                            margin: 0,
                            fontSize: "0.8rem"
                          }}
                        >
                          campo obrigatório
                        </p>
                      ) : (
                        ""
                      )
                    }
                  />
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    Bairro
                  </h4>
                  <TextField
                    name="neighborhood"
                    style={{ backgroundColor: "#fff" }}
                    onChange={e => handleAddressChange(e)}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    callback={e => handleAddressChange(e)}
                    value={address.neighborhood}
                    helperText={
                      emptyRequired.neighborhood === true ? (
                        <p
                          style={{
                            color: "red",
                            margin: 0,
                            fontSize: "0.8rem"
                          }}
                        >
                          campo obrigatório
                        </p>
                      ) : (
                        ""
                      )
                    }
                  />
                  <h4
                    style={{
                      margin: "20px 0 10px",
                      fontWeight: "500",
                      color: "#555555"
                    }}
                  >
                    Complemento
                  </h4>
                  <TextField
                    name="complement"
                    style={{ backgroundColor: "#fff" }}
                    onChange={e => handleAddressChange(e)}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    /* placeholder="Limite de 30 caracteres" */
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%"
                      }}
                    >
                      <h4
                        style={{
                          margin: "20px 0 10px",
                          fontWeight: "500",
                          color: "#555555"
                        }}
                      >
                        Cidade
                      </h4>
                      <TextField
                        name="city"
                        style={{ backgroundColor: "#fff" }}
                        helperText={
                          emptyRequired.city === true ? (
                            <p
                              style={{
                                color: "red",
                                margin: 0,
                                fontSize: "0.8rem"
                              }}
                            >
                              campo obrigatório
                            </p>
                          ) : (
                            ""
                          )
                        }
                        onChange={e => handleAddressChange(e)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        /* placeholder="Limite de 30 caracteres" */
                        value={address.city}
                        callback={e => handleAddressChange(e)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "30%"
                      }}
                    >
                      <h4
                        style={{
                          margin: "20px 0 10px",
                          fontWeight: "500",
                          color: "#555555"
                        }}
                      >
                        Estado
                      </h4>
                      <TextField
                        name="state"
                        style={{ backgroundColor: "#fff" }}
                        helperText={
                          emptyRequired.state === true ? (
                            <p
                              style={{
                                color: "red",
                                margin: 0,
                                fontSize: "0.8rem"
                              }}
                            >
                              campo obrigatório
                            </p>
                          ) : (
                            ""
                          )
                        }
                        onChange={e => handleAddressChange(e)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        /* placeholder="Limite de 30 caracteres" */
                        value={address.state}
                        callback={e => handleAddressChange(e)}
                      />
                    </div>
                  </div>
                  {/* <div className={classes.Slides} style={{ alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <h3>Possui contrato de negociação?</h3>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        name="contractActive"
                        onClick={() => {
                          setContractIntegration(!contractIntegration)
                        }}
                        checked={contractIntegration}
                      />
                    </div>
                  </div> */}
                  {/*                   {contractIntegration ? (
                    <>
                      <p>Número ou código do contrato</p>
                      <TextField
                        name="contract"
                        style={{ backgroundColor: "#fff" }}
                        helperText={
                          <p style={{ color: "red", margin: 0, fontSize: "0.8rem" }}>
                            campo obrigatório
                          </p>
                        }
                        onChange={e => handleChangeCredential(e)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        placeholder="Limite de 30 caracteres"
                      />
                      <p>Senha do Correios</p>
                      <TextField
                        name="password"
                        style={{ backgroundColor: "#fff" }}
                        type="password"
                        helperText={
                          <p style={{ color: "red", margin: 0, fontSize: "0.8rem" }}>
                            campo obrigatório
                          </p>
                        }
                        onChange={e => handleChangeCredential(e)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        placeholder="Limite de 30 caracteres"
                      />
                    </>
                  ) : (
                    ""
                  ) */}
                </>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Dialog className={classes.modalLeaving} open={leaving}>
              <div>
                <h2>
                  Salvar alterações do integração com os correios antes de sair?
                </h2>
                <p>
                  Existem informações da integração com os correios que ainda não
                  foram salvas
                </p>
              </div>
              <Button
                className={classes.btnFooterPink}
                /* disabled={} */
                onClick={() => setLeaving(false)}
                fullWidth
                variant="contained"
                color="secondary"
              >
                continuar editando
              </Button>
              <Button
                className={classes.btnFooterWhite}
                fullWidth
                variant="outlined"
                color="secondary"
              >
                sair sem salvar
              </Button>
            </Dialog>

            <Button
              className={classes.btnFooterPink}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                saveIntegration()
              }}
            >
              Salvar Informações
            </Button>
          </div>
        </div>
      </div>
      {showModalAlert ? (
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      ) : (
        ""
      )}
    </>
  )
}
