import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: "relative"
  },

  BoxSwitch: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  Input: {
    width: "100%",
    border: "1px solid #D0D0D0",
    padding: "10px",
    boxSizing: "border-box",
    borderRadius: "5px",
    fontSize: "0.9rem",
    "&:focus": {
      borderRadius: 4,
      border: "none",
      outlineColor: "#EA3458cc"
    }
  },

  InputTextArea: {
    resize: "none",
    height: "150px",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #D0D0D0",
    fontSize: "0.9rem",
    padding: "10px",
    "&:focus": {
      borderRadius: 4,
      border: "none",
      outlineColor: "#EA3458cc"
    }
  },

  boxAccordion: {
    marginTop: "25px"
  },

  customAccordion: {
    "& .MuiAccordionDetails-root": {
      flexDirection: "column",
      backgroundColor: "#EEEDED",
  },
  
    "& .MuiAccordion-region": {
      backgroundColor: "#EEEDED",
      border: "1px solid #F3F3F3",
      boxShadow: "none"
    },

    "& .MuiAccordionSummary-root": {
      backgroundColor: "#EEEDED"
    },

    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500"
    },

    "& p": {
      fontWeight: "500",
      fontSize: "14px",
    },

  },

  BoxButtonAdd: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px"
  },

  ButtonAdd: {
    backgroundColor: "transparent",
    border: "none",
    zIndex: "9999",
    padding: 0
  },

  DeleteIcon: {
    display: "flex",
    justifyContent: "end",
    color: "#7C7C7C"
  }
}))
