import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  ContainerForms: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  },
  InputBox: {
    "& input": {
      width: " 100%",
      border: "1px solid #D0D0D0",
      padding: "0.5rem 0.3rem",
      maxWidth: "535px",
      borderRadius: "5px",
      margin: 0
    },
    "& .ql-container" :{
      height: "200px",
      borderRadius: "0 0 8px 8px"
    }
  },
  InputLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  inputDescricao: {
    resize: "none",
    height: "150px",
    width: "500px",
    borderRadius: "5px",
    border: "1px solid #D0D0D0",
    fontSize: "0.9em",
    padding: "10px",
    "&:focus": {
      borderRadius: 4,
      border: "none",
      outlineColor: "#EA3458cc"
    }
  },

  qleditor:{
    width: "100%",
    height: "500px",
  },

}
))
