import { AiFillCheckCircle } from "react-icons/ai"
import { useStyles } from "./style"
import { button } from "../../../../Componentes/Inputs/TextFild"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import api from "../../../../Services/api"

export default function Insertcoupon(props) {
  const history = useHistory()
  const [cupom, setCupom] = useState("")
  const [inputAberto, setInputAberto] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false)

  const handleClick = () => {
    setInputAberto(!inputAberto)
  }

  function handleChange(e) {
    setCupom(e.target.value.replace(/\s/g, ''))
  }

  const [loading, setLoading] = useState(false)
  async function validateCupom() {
    setLoading(true)
    setShowError(false)
    try {
      const res = await api.get(`/cupom/validate/${cupom}`)
      sessionStorage.setItem("cupom_checkout", cupom)
      props.setNewPrice(res.data.data.new_value)
      props.handleSwitchComponents()
      setShowError(false)
    } catch (error) {
      setShowError(true)
    } finally {
      setLoading(false)
    }
  }

  const classes = useStyles()
  return (
    <>
      <div>
        <div className={classes.PlanComponent}>
          <AiFillCheckCircle
            style={{ color: "#52EB58", margin: "10px" }}
            size={34}
          />
          <div>
            <h3>
              12x <span>R$27,90</span> no plano anual
            </h3>
            <h4>ou R$ 334,80 à vista</h4>
          </div>
        </div>
      </div>
      {!inputAberto ? (
        <div className={classes.Cupom} onClick={handleClick}>
          <u>
            {" "}
            <h3> Utilizar cupom de desconto</h3>{" "}
          </u>
        </div>
      ) : (
        <div className={classes.Desconto}>
          <h3>Cupom de desconto: </h3>
          <div className={classes.BoxCupom}>
            <input
              value={cupom}
              onChange={e => handleChange(e) }
              className={classes.Inputbtn}
              type="text"
              placeholder=""
              style={{border: showError ? '2px solid red' : '2px solid #D0D0D0'}}
            />
            <button
              className={classes.Btnblue}
              onClick={() => {validateCupom()}
              }
              disabled={cupom===""}
              style={{backgroundColor: cupom === '' ? '#D0D0D0' : '#283575'}}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    minHeight: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                    
                  }}
                >
                  <CircularProgress size={20} color="#fff" />
                </div>
              ) : (
                "Validar cupom"
              )}
            </button>
          </div>
          <span
            hidden={showError === false}
            style={{ color: "red", fontSize: "0.7rem", textAlign: "center" }}
          >
            Cupom inválido
          </span>{" "}
        </div>
      )}
      <div>
        <div>
          <div className={classes.Button}>
            {button({
              name: `continuar`,
              color: "#52EB58",
              funcao: () => history.push("/planos/v2/checkout")
            })}
          </div>
        </div>
        <div className={classes.Footer}>
          <h3>
            Sua satisfação garantida: compre com tranquilidade, cancelamento e
            reembolso em até 7 dias após a compra.
          </h3>
        </div>
      </div>
    </>
  )
}
