import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Slides: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      display: "flex"
    }
  },
  buttonCategories: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#fff",
    cursor: "pointer",
    border: "1px solid #A4A4A4",
    padding: "10px",
    borderRadius: "5px",
    alignItems: "center",
  },
}))
