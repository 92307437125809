import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    main: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },

    deleteIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#EA3458",
        position: "absolute",
        left: "94%",
        top: "-15px",
        padding: "5px",
        borderRadius: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",

        "&:hover": {
            background: "#FA6482",
        },

        '@media (min-width: 320px)': {
            left: "85%"
        },

        '@media (min-width: 425px)': {
            left: "90%"
        },

        '@media (min-width: 768px)': {
            left: "92%"
        }
    },

    boxImageDefault: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EAEAEA",
        borderRadius: "5px",
        border: "2px dashed #A4A4A4",
        width: "350px",
        height: "250px",
    }
})
export default useStyles