import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  banner: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    width: "100%",
    background: "#051626",
    padding: "0 15px",
    boxSizing: "border-box"
  },

  bannerLarge: {
    display: "flex",
    justifyContent: "space-between",
    height: "15%",
    width: "100%",
    background: "#051626",
    padding: "0 15px",
    boxSizing: "border-box"
  },

  bannerLeftSide: {
    display: "flex"
  },

  bannerRightSide: {
    display: "flex",
    alignItems: "center"
  },

  imgBanner: {
    height: "60px",
    width: "60px",
    padding: "10px 10px "
  },

  titleBanner: {
    fontSize: "2vw",
    margin: "5px 0px",
    color: "#fff"
  },

  subtitleBanner: {
    fontSize: "1.0vw",
    margin: "5px 0px 0px",
    color: "#fff",
    paddingRight: "20px"
  },

  btnApple: {
    margin: "auto 10px",
    height: "60px",
    width: "200px"
  },

  btnAndroid: {
    margin: "auto 10px",
    height: "60px",
    width: "200px"
  },

  closeBtn: {
    height: "50px",
    width: "50px",
    color: "#fff",
    margin: "0px"
  }
}))
