import { Delete } from "@material-ui/icons";
import addPictureIcon from "../../Assets/svg/addPictureIcon.svg";
import useStyles from "./style";

export default function ImgPreview({ image, width, height, setImage, description}) {
    const classes = useStyles()
    return (
        <div className={classes.main}>
            <div className={classes.deleteIconContainer} onClick={() => setImage("")}>
                <Delete style={{color: "white"}} /> 
            </div>
            {
                image
                    ?
                    <img src={image} alt="banner" style={{ width: "100%", maxHeight: "300px" }} />
                    :
                    <div className={classes.boxImageDefault}>
                        <img
                            src={addPictureIcon}
                            alt="addPictureIcon-icon"
                            width={98}
                            height={98}
                        />
                    </div>
            }
            <span>{description}:<br />({width}x{height})px</span>
        </div>
    )
}
