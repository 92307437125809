import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
    textareaContainer: {
        paddingTop: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        "& span": {
          fontSize: "0.9rem",
          color: "#555"
        },
        "& .ql-container": {
          height: "200px",
          borderRadius: "0 0 8px 8px"
        }
      },
}))
