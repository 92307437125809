import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import * as React from "react"
import { button } from "../../Componentes/Inputs/TextFild"
import CategoryComponent from "./Components/index"
import update from "immutability-helper"
import { AddCircle } from "@material-ui/icons"
import { useHistory } from "react-router-dom"
import categoriasNovaRequest from "../../Services/api/categoriasnova"
import { CircularProgress } from "@material-ui/core"
import { useLocation } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"

export default function CategoriasNova({ showTopo, from, handleStep }) {
  const classes = useStyles()
  const history = useHistory()
  const [newCategoryName, setNewCategoryName] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [categories, setCategories] = React.useState([])
  const location = useLocation()
  const [alertType, setAlertType] = React.useState("")
  const [showModalAlert, setShowModalAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")

  async function getCategoriasNova() {
    setLoading(true)
    try {
      const { data } = await categoriasNovaRequest.getCategoriasNova()
      setCategories(data.data)
    } catch (error) {
    } finally {
      setLoading(false)
      console.log(error)
    }
  }

  async function sendNew() {
    if (newCategoryName.trim() !== "") {
      try {
        setAlertType("success")
        setAlertMessage("Categoria adicionada com sucesso!")
        setShowModalAlert(true)
        await categoriasNovaRequest.postCategoriasCreate({
          category_name: newCategoryName
        })
        setNewCategoryName("")
        getCategoriasNova()
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    }
  }

  React.useEffect(() => {
    getCategoriasNova()
  }, [])

  const handleDeleteCategory = async categoryId => {
    try {
      await categoriasNovaRequest.deleteCategoriaDelete(categoryId)
      getCategoriasNova()
      setAlertType("success")
      setAlertMessage("Categoria excluída com sucesso!")
      setShowModalAlert(true)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className={`${classes.mainContainer} page-container`}>
      <div className="page-align">
        <div className="infinite-scroll">
          <Topo
            name="Categorias"
            show={showTopo ?? true}
            rota={from === "sessao-guiada" ? "/sessao-guiada" : "/home"}
            stepSessaoGuiada={from === "sessao-guiada" && 5}
          />
          <div>
            <div className={classes.BoxSwitch}>
              <input
                className={classes.InputAddCircle}
                placeholder="Nome da categoria"
                value={newCategoryName}
                onChange={e => setNewCategoryName(e.target.value)}
              ></input>
              <AddCircle
                onClick={sendNew}
                style={{ color: "#EA3458", fontSize: "50px", cursor: "pointer" }}
              />
            </div>
          </div>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0px"
              }}
            >
              <CircularProgress style={{ color: "#EA3458" }} />
            </div>
          ) : (
            categories?.map((category, index) => (
              <div className={classes.ContainerMap}>
                <CategoryComponent
                  handleDeleteCategory={handleDeleteCategory}
                  key={category.category_id}
                  index={index}
                  id={category.category_id}
                  name={category.category_name}
                  categories={categories}
                  handleClick={() => history.push("/subcategorias")}
                  from={from}
                  handleStep={handleStep}
                  isCategory
                  setCategories={setCategories}
                  newCategoryName={newCategoryName}
                  threepoints
                />
                <div>
                  <CustomAlert
                    type={alertType}
                    alertText={alertMessage}
                    show={showModalAlert}
                    close={() => setShowModalAlert(true)}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}
