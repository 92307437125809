import { makeStyles } from "@material-ui/core/styles"
// import { button } from "../../Componentes/Inputs/TextFild"

export const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    backgroundColor: "#0D273D",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    border: "1px solid #0D273D",
    height: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",

    "& h3": {
      fontSize: "0.8rem",
      color: "#FFFFFF",
      fontWeight: "400"
    },

    "& span": {
      fontWeight: "bold",
      color: "#FFFFFF"
    }
  },

  logoContainer: {
    display: "flex",
    alignItems: "flex-end"
  },

  logo: {
    marginRight: "10px"
  },

  containerContent: {
    border: "1px solid #0D273D",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    padding: "1rem"
  },

  EtapaPagamentoContainer: {
    "& .rccs, & .rccs__card": {
      maxWidth: "280px!important"
    }
  },
  FormCartao: {
    marginTop: "1rem",
    "& div": {
      display: "flex",
      flexDirection: "column",
      margin: "5px 0"
    },
    "& > div": {
      margin: "5px 0"
    },
    "& label": {
      fontSize: "0.8rem",
      padding: "5px 0",
      fontWeight: "400",
      color: "#555555"
    },
    "& input": {
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      height: "40px"
    }
  },

  Card: {
    width: "100%",
    maxWidth: "520px",
    position: "relative"
  },

  logoInput: {
    position: "absolute",
    width: "30px",
    right: "10px",
    top: "35px"
  },

  FormCartaoInputGroup: {
    flexDirection: "row!important",
    justifyContent: "space-between",
    "& .validadeInput": {
      width: "60%"
    },
    "& .cvvInput": {
      width: "35%"
    }
  },
  CyclesInput: {
    height: "40px",
    fontSize: "1rem",
    padding: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    width: "100%"
  },

  Button: {
    marginTop: "20px"
  }
}))
