import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import Switch from "@material-ui/core/Switch"
import { useState, useEffect } from "react"
import * as React from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import addProductIcon from "../../Assets/svg/addProductIcon.svg"
import { button } from "../../Componentes/Inputs/TextFild"
import IconButton from "@material-ui/core/IconButton"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import faqRequest from "../../Services/api/faq"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function DuvidasFrequentes() {
  const classes = useStyles()

  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const history = useHistory()
  const handleRedirect = () => {
    history.replace("/personalizar-lojinha/complementos")
    history.goBack()
  }
  const [bodyFaq, setBodyFaq] = useState({
    complements_faq_status: false,
    complements_faq: [{ question: "Pergunta 1", awnser: "" }]
  })

  const [accordions, setAccordions] = useState([
    { question: "Pergunta 1", answer: "" }
  ])

  const handleAddAccordion = () => {
    const newAccordion = {
      question: "",
      awnser: ""
    }
    setAccordions([...accordions, newAccordion])

    setBodyFaq({
      ...bodyFaq,
      complements_faq: [...bodyFaq.complements_faq, newAccordion]
    })
  }

  const handleDeleteAccordion = index => {
    const updatedAccordions = [...bodyFaq.complements_faq]
    updatedAccordions.splice(index, 1)
    setBodyFaq({
      ...bodyFaq,
      complements_faq: updatedAccordions
    })
  }
  const handleSaveChanges = async () => {
    setLoading(true)
    try {
      const isEmptyQuestion = bodyFaq.complements_faq.some(
        item => item.question.trim() === "" || item.awnser.trim() === ""
      )

      if (isEmptyQuestion) {
        setAlertType("error")
        setAlertMessage("Preencha todas as perguntas e respostas antes de salvar.")
        setShowModalAlert(true)
      } else {
        const { data } = await faqRequest.putFaq(bodyFaq)
        setAlertType("success")
        setAlertMessage("Perguntas salvas com sucesso!")
        setShowModalAlert(true)
        setTimeout(() => {
          handleRedirect()
        }, 1000)
      }
    } catch (error) {
      console.error(error)
      setAlertType("error")
      setAlertMessage("Erro ao salvar pergunta!")
      setShowModalAlert(true)
    } finally {
      setLoading(false)
    }
  }

  async function getData() {
    setLoading(true)
    try {
      const { data } = await faqRequest.getFaq()
      setBodyFaq({
        complements_faq_status: data.data.complements_faq_status,
        complements_faq: data.data.complements_faq.map(item => ({
          question: item.question,
          awnser: item.awnser
        }))
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className={`${classes.mainContainer} page-container`}>
        <div className="page-align">
          <Topo name="Dúvidas frequentes" />
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
                <div className={classes.BoxSwitch}>
                  <label>Ativar</label>
                  <Switch
                    onClick={() => {
                      setBodyFaq({
                        ...bodyFaq,
                        complements_faq_status: !bodyFaq.complements_faq_status
                      })
                    }}
                    checked={bodyFaq.complements_faq_status}
                  />
                </div>
                <div className={classes.BoxButtonAdd}>
                  <button className={classes.ButtonAdd} onClick={handleAddAccordion}>
                    <img
                      src={addProductIcon}
                      width={55}
                      height="auto"
                      alt="addProductIcon-icon"
                    />
                  </button>
                </div>

                <div>
                  {bodyFaq.complements_faq.map((accordion, index) => (
                    <div key={accordion.id} className={classes.boxAccordion}>
                      <Accordion
                        expanded={expanded === index}
                        onChange={() =>
                          setExpanded(prev => (prev === index ? false : index))
                        }
                        sx={{ boxShadow: "none" }}
                        disabled={!bodyFaq.complements_faq_status}
                        className={classes.customAccordion}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${accordion.id}-content`}
                          id={`panel${accordion.id}-header`}
                        >
                          <Typography>Pergunta {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <input
                              className={classes.Input}
                              value={bodyFaq.complements_faq[index].question}
                              onChange={e => {
                                const updatedFaq = [...bodyFaq.complements_faq]
                                updatedFaq[index].question = e.target.value
                                setBodyFaq({
                                  ...bodyFaq,
                                  complements_faq: updatedFaq
                                })
                              }}
                              disabled={!bodyFaq.complements_faq_status}
                            />
                          </Typography>
                          <Typography>
                            <p>Resposta</p>
                            <textarea
                              className={classes.InputTextArea}
                              value={bodyFaq.complements_faq[index].awnser}
                              onChange={e => {
                                const updatedFaq = [...bodyFaq.complements_faq]
                                updatedFaq[index].awnser = e.target.value
                                setBodyFaq({
                                  ...bodyFaq,
                                  complements_faq: updatedFaq
                                })
                              }}
                            />
                          </Typography>
                          <div className={classes.DeleteIcon}>
                            <IconButton onClick={() => handleDeleteAccordion(index)}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            {button({
              name: "Salvar alterações",
              funcao: () => handleSaveChanges(),
              disabled: loading
            })}
          </div>
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </>
  )
}
