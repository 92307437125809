import { SimpleImg } from "react-simple-img"
import noImageProduct from "../../../../../../Assets/img/noProductImg.png"
import BannerSejaPro from "../../../../../../Componentes/BannerSejaPro"
import { useStyles } from "./style"

export default function FreeWantedProducts({ products }) {
  const classes = useStyles()
  const mockProducts = [
    { title: "produto lorem ipsum", price: "R$ 150,00" },
    { title: "produto lorem ipsum", price: "R$ 50,00" },
    { title: "produto lorem ipsum", price: "R$ 80,00" }
  ]
  return (
    <>
      <section style={{ marginTop: "15px" }}>
        <div className={classes.productContainer}>
          <h2>1º</h2>
          <div className={classes.productContent}>
            <SimpleImg
              width={47}
              height="auto"
              placeholder={false}
              src={
                products[0] && products[0].images[0]
                  ? products[0].images[0].sm_url
                  : noImageProduct
              }
              alt="img Produto"
              style={{ borderRadius: "5px" }}
            />
            <div className={classes.productInfos}>
              <p>{products[0] && products[0].product_title}</p>
              <small>
                Preço:{" "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(products[0] && products[0].price)}
              </small>
            </div>
          </div>
        </div>
      </section>

      {mockProducts.map((arr, idx) => (
        <section key={idx} style={{ marginTop: "15px", filter: "blur(3px)" }}>
          <div className={classes.productContainer}>
            <h2>{idx + 2}º</h2>
            <div className={classes.productContent}>
              <SimpleImg
                width={47}
                height="auto"
                placeholder={false}
                src={noImageProduct}
                alt="img Produto"
              />
              <div className={classes.productInfos}>
                <p>{arr.title}</p>
                <small>Preço: {arr.price}</small>
              </div>
            </div>
          </div>
        </section>
      ))}
      <div className={classes.banner}>
        <BannerSejaPro bannerId="banner-home" route="home" />
      </div>
    </>
  )
}
