import ApiRequest from "../index"

const requestInstitucional = {
    getInstitucionalInfos: async () => ApiRequest.get("v2/lojinha/institutional/list"),
    updateInstitucionalInfos: async (status, institucionalImage, text) => ApiRequest.put("v2/lojinha/institutional/update", {
        institutionals_status: status,
        institutionals_image: institucionalImage,
        institutionals_body: text
    }),
}

export default requestInstitucional