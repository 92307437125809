import { useEffect, useState } from "react"
import api from "../../../../Services/api"
import { IoMdCopy } from "react-icons/io"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import sendIcon from "../../../../Assets/svg/send-mail.svg"
import { useStyles } from "./style"

export default function PedidoEntrega({
  deliveryMethod,
  deliveryInfos,
  deliveryPrice,
  melhorEnvio,
  orderID
}) {
  const classes = useStyles()

  const [alertType, setAlertType] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState("")

  const [deliveryInfo, setDeliveryInfo] = useState({
    address: {},
    method: "",
    price: "",
    melhorEnvio: false
  })

  useEffect(
    () =>
      setDeliveryInfo({
        address: deliveryInfos,
        method: deliveryMethod,
        price: deliveryPrice,
        melhorEnvio
      }),
    [deliveryMethod, deliveryInfos, deliveryPrice, melhorEnvio]
  )

  const { street, number, state, city, complement, neighborhood, zip_code } =
    deliveryInfo.address

  function copyText() {
    const text = `${street || ""}, nº ${number || ""}, ${complement || ""} - ${
      neighborhood || ""
    }, ${city || ""} - ${state || ""}, CEP: ${zip_code || ""}`
    navigator.clipboard.writeText(text)

    setAlertText("Endereço copiado com sucesso")
    setAlertType("success")
    setShowAlert(true)
  }
  async function sendTicket() {
    await api.post("order/mail/ticket", { order_id: orderID }).then(res => {
      setAlertText("Etiqueta enviada para seu email!")
      setAlertType("success")
      setShowAlert(true)
    })
  }
  return (
    <>
      {deliveryInfo.method !== "Retirada na loja" && (
        <>
          <div>
            <p className={classes.titleDelivery}>Endereço de Entrega:</p>
            <p id="address_id">
              {`${street || ""}, nº ${number || ""}, ${complement || ""} - ${
                neighborhood || ""
              }, ${city || ""} - ${state || ""}, CEP: ${zip_code || ""}`}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px"
            }}
          >
            <button onClick={e => copyText()} className={classes.btnCopyDelivery}>
              <IoMdCopy fontSize="1.5rem"></IoMdCopy>
              Copiar dados de entrega
            </button>
          </div>
        </>
      )}

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <p style={{ margin: 0 }} className={classes.titleDelivery}>
            Método de Entrega: <span>{deliveryInfo?.method || "Entrega"}</span>
          </p>
        </div>

        {melhorEnvio && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "25px 0 10px 0"
            }}
          >
            <button className={classes.btnTicketSend} onClick={() => sendTicket()}>
              Enviar etiqueta de entrega para o meu e-mail de cadastro
              <img
                src={sendIcon}
                alt="send-button"
                style={{
                  margin: "1rem"
                }}
              ></img>
            </button>
          </div>
        )}

        <p
          style={{
            fontWeight: 500,
            color: "#555555",
            margin: "7px 0 0 0"
          }}
        >
          Valor do Frete:
          <span style={{ marginLeft: "5px", fontWeight: "700" }}>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2
            }).format(deliveryInfo.price)}
          </span>
        </p>

        {deliveryInfo.method === "Frete Grátis" && (
          <p
            style={{
              color: "#555555",
              fontStyle: "italic",
              fontWeight: 400,
              fontSize: "11px"
            }}
          >
            (Aplicado frete grátis devido as suas regras criadas no sistema)
          </p>
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomAlert
          type={alertType}
          alertText={alertText}
          show={showAlert}
          close={() => setShowAlert(false)}
        />
      </div>
    </>
  )
}
